// This file is intentionally written to not fail in old browsers
//
// NOTE: this file should not import anything (except for TS types)

import { AnalyticsEventInput } from "../generated/types";

export default function safePutAnalytics({
  mutationPrefix,
  events,
}: {
  mutationPrefix: string;
  events: AnalyticsEventInput[];
}) {
  // NOTE: DO NOT LOG requests within this function
  try {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open(
      "POST",
      `${window.REACT_APP_API_BASE_URL}?name=${mutationPrefix}Mutation`
    );
    const params = {
      operation: `${mutationPrefix}Mutation`,
      query: `
          mutation ${mutationPrefix}($input: PutAnalyticsEventsInput!) {
            putAnalyticsEvents(input: $input) {
              success
              errorMessage
              results {
                acceptCount
                rejectCount
                rejectedEvents {
                  index
                  name
                  reason
                }
                totalCount
              }
            }
          }
          `,
      variables: {
        input: {
          currentTime: Math.round(Date.now() / 1000),
          events,
        },
      },
    };
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify(params));

    // NOTE: don't try to use the (err) argument here because Chrome <66 doesn't support that
  } catch {
    // do nothing at all (and DO NOT LOG)
    //
    // we intentionally don't log here due to this being used for remote logging
    // and thus we don't want to cause a loop
  }
}
