/** @jsxImportSource @emotion/react */

import appVersion from "./lib/appVersion";
import modernBrowsers from "./lib/generated/modernBrowsers";
import supportedBrowsers from "./lib/generated/supportedBrowsers";
import safePutAnalytics from "./lib/safePutAnalytics";
import "./setEnvironmentVariables";
import unsupportedHTML from "./unsupported.html";

// IMPORTANT: everything in this file must be written using syntax that is supported by very old browsers
const whitelistedUserAgentParts = [
  // Apple messed up this user agent during a rapid security response patch release to Safari
  // https://app.asana.com/0/913421767884888/1205062200495185/f
  "Version/16.5.2 (a) Safari",
  // Google AdsBot
  // https://app.asana.com/0/1201497668075595/1205094667116718/f
  "AdsBot-Google",
];

let isWhitelistedUserAgent = false;
for (let i = 0; i < whitelistedUserAgentParts.length; i++) {
  // Internet Explorer doesn't have String.prototype.includes, so must use indexOf (otherwise this will crash before our Unsupported Browser page shows)
  if (navigator.userAgent.indexOf(whitelistedUserAgentParts[i]) !== -1) {
    isWhitelistedUserAgent = true;
    break;
  }
}

const isSupportedBrowser =
  supportedBrowsers.test(navigator.userAgent) || isWhitelistedUserAgent;

const isModernBrowser = modernBrowsers.test(navigator.userAgent);

safePutAnalytics({
  mutationPrefix: "PageLoadAnalyticsEvent",
  events: [
    {
      eventName: "WebApp.pageload",
      eventTime: Math.round(Date.now() / 1000),
      properties: [
        {
          key: "user-agent",
          stringValue: {
            value: navigator.userAgent,
          },
        },
        {
          key: "appversion",
          stringValue: {
            value: appVersion,
          },
        },
        {
          key: "windowWidth",
          intValue: {
            value: window.innerWidth,
          },
        },
        {
          key: "windowHeight",
          intValue: {
            value: window.innerHeight,
          },
        },
        {
          key: "devicePixelRatio",
          intValue: {
            value: window.devicePixelRatio,
          },
        },
        {
          key: "isSupportedBrowser",
          boolValue: {
            value: isSupportedBrowser,
          },
        },
        {
          key: "isModernBrowser",
          boolValue: {
            value: isModernBrowser,
          },
        },
      ],
    },
  ],
});

if (isSupportedBrowser) {
  if (!isModernBrowser) {
    // @ts-ignore this is complaining about a missing TS definition for the module, but we are not directly importing anything so it's safe to ignore
    import("core-js/es");
  }

  (async () => {
    // We initialize Bugsnag as early as possible in order to:
    // 1. be able to record as much happening in the app as possible to Bugsnag
    // 2. prevent our `logger` from calling `Bugsnag.leaveBreadcrumb` before Bugsnag is initialized,
    //    which results in an annoying console log
    //
    // Furthermore, we `require` it instead of importing at the top of the file
    // so that we can avoid loading it in the unsupported browser case.
    const bootstrapBugsnag = (await import("./bootstrapBugsnag")).default;
    bootstrapBugsnag();

    import("./bootstrap");
  })();
} else {
  window.document.body.innerHTML = unsupportedHTML;
}
