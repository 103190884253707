import { LightModeColor } from "./components/Colors";

const unsupportedHTML = `
<style>
  body {
    font-family: sans-serif;
    padding: 40px 20px;
    max-width: 720px;
    width: 100%;
    margin: 0 auto;
    line-height: 1.75;
  }
  h1 { text-align: center; }
  p { margin-bottom: 40px; }
  a { color: ${LightModeColor.Blue7}; border-bottom: 2px solid ${LightModeColor.Blue7}; }
</style>
<h1>Browser Not Supported</h1>
<p style="margin-bottom: 0">
  You are attempting to access Spruce with a web browser that we do not support. This most commonly occurs when a browser has become too old to guarantee the security that is needed for medical information. To access Spruce, please upgrade to the current version of a modern web browser, such as:
</p>
<ul style="margin-top: 18px">
<li><a href="https://brave.com/" target="_blank">Brave</a> – min. version 1.35</li>
<li><a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a> – min. version 98</li>
<li><a href="https://www.mozilla.org/firefox/new/" target="_blank">Firefox Browser</a> (best for older computers) – min. version 96</li>
<li><a href="https://www.microsoft.com/edge">Microsoft Edge</a> – min. version 97</li>
<li>macOS Safari – min. version 14</li>
<li>iOS Safari – min. version 9.0</li>
</ul>
<p>
  For more information on this topic, or to send us questions or comments, please visit the <a href="https://help.sprucehealth.com/" target="_blank">Spruce Help Center</a>.
</p>
`;

export default unsupportedHTML;
